import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/* import "react-datepicker/dist/react-datepicker.css" */
import { injected } from "components/wallet/connectors"
import { useWeb3React } from "@web3-react/core"
import { UnsupportedChainIdError } from '@web3-react/core'

import Input from "components/common/input"
import ContentWrapper from "styles/contentWrapper"
import metamaskLogo from "content/metamask.svg"
import bscLogo from "content/bsclarge.png"
import plgIcon from "content/icon.png"
import bscIcon from "content/bscicon.svg"

import { isSSR, detectMobileAndTablet } from "utils"
// ido
const contractAbi = [{"inputs":[{"internalType":"address[]","name":"whos","type":"address[]"},{"internalType":"uint256[]","name":"amounts","type":"uint256[]"}],"name":"addAdvisor","outputs":[],"stateMutability":"nonpayable","type":"function"},{"inputs":[{"internalType":"address","name":"_token","type":"address"},{"internalType":"uint256","name":"_numDays","type":"uint256"},{"internalType":"address[]","name":"_whos","type":"address[]"},{"internalType":"uint256[]","name":"_amounts","type":"uint256[]"}],"stateMutability":"nonpayable","type":"constructor"},{"inputs":[{"internalType":"uint256","name":"amount","type":"uint256"}],"name":"withdraw","outputs":[],"stateMutability":"nonpayable","type":"function"},{"inputs":[],"name":"canWithdraw","outputs":[{"internalType":"uint256","name":"","type":"uint256"}],"stateMutability":"view","type":"function"}]
const contractAddress = "0xd9bf169776582Ced4341918C870291989EEd7C86"



const StyledSection = styled.section`
  width: 100%;
  height: auto;
  padding-top: 7rem;
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 1.05rem;
    padding-bottom: 5rem;
    .section-title {
      margin: auto;
      margin-bottom: 3rem;
    }
    .content {
      margin-top: 3rem;
      display: flex;
      justify-content: center; 
      align-items: center;
    }
  }
`

const StyledIntro = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 420px;
  width: 100%;
  margin: auto;
  margin-top: 3rem;
  margin-bottom: 1rem;
  .title {
    font-size: 1rem;
    line-height: 1.2rem;
    font-weight: 600;
  }
  .coins {
    display: flex;
    .icon-wrapper {
      box-shadow: rgb(0 0 0 / 1%) 0px 0px 1px, rgb(0 0 0 / 4%) 0px 4px 8px, rgb(0 0 0 / 4%) 0px 16px 24px, rgb(0 0 0 / 1%) 0px 24px 32px;
      border-radius: 100%;
      width: 2.3rem;
      height: 2.3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: white;
      img {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
`

const StyledForm = styled.form`
  max-width: 420px;
  width: 100%;
  margin: auto;
  box-shadow: 0 6px 10px rgb(0 0 0 / 8%);
  height: 100%;
  border-radius: 0.5rem;
  background: #fff;
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
  border-image: initial;
  transform: perspective(100px);
  color: #212529;
  position: relative;
  /* background: rgb(255, 255, 255); */
  box-shadow: rgb(0 0 0 / 1%) 0px 0px 1px, rgb(0 0 0 / 4%) 0px 4px 8px, rgb(0 0 0 / 4%) 0px 16px 24px, rgb(0 0 0 / 1%) 0px 24px 32px;
  border-radius: 30px;
  padding: 1rem
  .content {
    min-height: 30rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .card__content {
    padding: 1.5rem;
  }
  .card__bottom {
    border-top: 1px solid #fff;
    padding: 1.5rem;
    background-color: rgba(195, 195, 195, 0.14);
    text-align: center;
    div:first-of-type {
      color: #16385A;
      font-weight: 600;
      margin-bottom: 0.5rem;
    }
  }
  .subtitle {
    font-weight: 600;
    margin-bottom: 1rem;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      padding: 0.5rem 0;
      display: flex;
      justify-content: flex-start;
      font-size: 1rem;
      line-height: 1.2rem;
      svg {
        margin-right: 1rem;
      }
    }
  }

`
const StyledActions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  .plunge-button {
    border-radius: 0.5rem;
  }
  .plunge-button.disabled {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
  }
`

const StyledButtonMin = styled.button`
  width: fit-content;
  float: right;
  cursor: pointer;
  height: 28px;
  background-color: rgb(204, 0, 88);
  border: 1px
  solid rgb(204, 0, 88);
  border-radius: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  margin-right: 0.5rem;
  color: rgb(255, 255, 255);
`

const StyledButton = styled.button`
  border-color: transparent;
  border-radius: 0.4rem;
  color: white;
  background: #00A9E0;
  letter-spacing: 1.54px;
  border: 1px solid;
  display: inline-block;
  font-size: 0.9rem;
  line-height: 1.2rem;
  font-weight: 600;
  padding: 0.9rem 1.2rem;
  transition: all .5s ease-in-out;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  svg {
    margin-left: 0.7rem;
  }
  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
  }
`

const StyledMetamaskButton = styled.button`
  width: 31rem;
  border: 1px solid #ccc;
  text-align: center;
  border-radius: 0.4rem;
  background: white;
  letter-spacing: 1.54px;
  display: inline-block;
  padding: 2rem;
  max-width: 90%;
  transition: all .5s ease-in-out;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    margin-bottom: 1rem;
    height: 4rem;
  }
  .title {
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.4rem;
    margin-bottom: 1rem;
  }
  .text {
    font-size: 1rem;
    color: rgb(169, 169, 188);
  }
  &:hover {
    background-color: rgba(195, 195, 195, 0.14);
    transform: scale(1.02);
  }
`
const StyledControl = styled.div`
  margin: 1rem 0;
  font-size: 1rem;
  .label {
    letter-spacing: -.05px;
    margin-bottom: 0.4rem;
    display: block;
    font-weight: 600;
    font-size: 1rem;
    color: #425a70;
    &.inline {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.5rem;
      .tag {
        border: 1px solid rgb(209, 213, 219);
        padding: 0.3rem 0.5rem;
        display: flex;
        align-items: center;
        border-radius: 0.2rem;
        img {
          width: 1.5rem;
          height: auto;
          margin-right: 0.4rem;
        }
      }
    }
  }
  small {
    color: #66788a;
    font-size: 0.7rem;
  }
  &.inline {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;
  }
  /* input {
    outline: none;
    border: 1px solid #C6D7EA;
    border-radius: 0.5rem;
    background-color: white;
    padding: 0.7rem 1rem 0.6rem 1rem;
    display: block;
    width: 100%;
    box-sizing: border-box;
    font-size: 0.9rem;
    line-height: 1.4rem;
    color: #6485A8;
  } */
`

const StyledError = styled.div`
  padding: 1rem;
  border: 1px solid ${props => props.hint ? '#563cc9' : 'red'};
  border-radius: 0.5rem;
  width: 31rem;
  max-width: 100%;
  margin: auto;
  margin-top: 1rem;
  color: ${props => props.hint ? '#563cc9' : 'red'};
`

const StyledHint = styled.div`
  padding: 1rem;
  text-align: center;
  width: 31rem;
  max-width: 100%;
  margin: auto;
  margin-top: 3rem;
  box-shadow: 0 6px 10px rgb(0 0 0 / 8%);
  height: 100%;
  border-radius: 0.5rem;
  background: #fff;
  border-left: 2px solid rgb(24, 61, 99);
  a {
    color: #3a6cae;
    text-decoration: underline;
    &:hover {
      opacity: 0.8;
    }
  }
  img {
    height: 2rem;
    width: 2rem;
  }
  .button-empty {
    padding: 0.5rem 1rem;
    border: 1px solid #eee;
    border-radius: 0.4rem;
    text-align: center;
    margin-top: 1rem;
    color: #16385A;
    font-weight: 600;
    text-decoration: none;
  }
`

const AdvisorsWithdraw = () => {
  const { active, account, library, connector, activate, deactivate, error } = useWeb3React()
  const [errored, setErrored] = useState(0)
  const [windowWidth, setWindowWidth] = useState(0)
  const [tried, setTried] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [whitelisted, setIsWhitelisted] = useState(false)
  const [metamaskInstalled, setMetamaskInstalled] = useState(true)
  const [balance, setBalance] = useState(null)
  const [step, setStep] = useState(1)
  /* const [plgAmount, setplgAmount] = useState(0) */
  const [plgAmount, setPlgAmount] = useState(0)
  const [plgBalance, setPlgBalance] = useState(null)
  const [plgAvailable, setPlgAvailable] = useState(null)

  useEffect(() => {
    let handleWindowSizeChange
    // if (isSSR) is necessary to prevent error during the gatsby build
    if (!isSSR) {
      handleWindowSizeChange = () => setWindowWidth(window.innerWidth)
      // set initial innerWidth when component mounts
      setWindowWidth(window.innerWidth)
    }
    // Add event listener to update windowWidth in state
    window.addEventListener("resize", handleWindowSizeChange)
    return () => window.removeEventListener("resize", handleWindowSizeChange)
  }, [windowWidth])


  useEffect(() => {
    if(!isSSR) {
      if (typeof window.ethereum !== 'undefined' || detectMobileAndTablet(windowWidth)) {
        setMetamaskInstalled(true)
      } else {
        setMetamaskInstalled(false)
      }
    }
  }, [windowWidth])

  useEffect(() => {
    if(active) {
      whitelistCheck()
    }
  }, [active])

  useEffect(() => {
    if(whitelisted) {
      getInfo()
    }
  }, [whitelisted])

  useEffect(() => {
    injected.isAuthorized().then((isAuthorized) => {
      if (isAuthorized) {
        activate(injected, undefined, true).catch(() => {
          setTried(true)
        })
      } else {
        setTried(true)
      }
    })
  }, [])
  
  async function connect() {
    try {
      await activate(injected)
    } catch (ex) {
      console.log(ex)
    }
  }

  async function switchNetwork() {
    try {
      if(!isSSR) {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: "0x38" }],
        });
      }
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          if(!isSSR) {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              blockExplorerUrls: "https://bscscan.com",
              params: [{ 
                chainId: "0x38",
                chainName: "Binance smart chain",
                rpcUrls: ['https://bsc-dataseed.binance.org'],
                nativeCurrency: {
                  symbol: "BNB", // 2-6 characters long
                  decimals: 18,
                }
              }],
            });
          }
        } catch (addError) {
          // handle "add" error
        }
      }
      // handle other "switch" errors
    }
  }

  async function whitelistCheck () {
    const web3 = library
    const privateSaleContract = new web3.eth.Contract(contractAbi,contractAddress, {from: account})
  /*   privateSaleContract.methods.isWhitelisted().call().then(res => {
      console.log('res', res)
      
    }) */
    setIsWhitelisted(true)
  }

  async function Withdraw() {
    const web3 = library
    setButtonLoading(true)
    setErrored(false)
    const privateSaleContract = new web3.eth.Contract(contractAbi,contractAddress, {from: account})
    privateSaleContract.methods.withdraw(`${plgAmount}000000000000000000`).send().then(res => {
      getInfo()
      setButtonLoading(false)
      toast("PLG withdrawed successfully!")
    }).catch(error => {
      setButtonLoading(false)
      setErrored(true)
      toast.error("Something went wrong!")
    })
  }

 

  async function getInfo() {
    const web3 = library
    const privateSaleContract = new web3.eth.Contract(contractAbi,contractAddress, {from: account})
    privateSaleContract.methods.canWithdraw().call().then(res => {
      // const newPlgBalance = parseInt(res[0])/(10**18)
      console.log('res', res)
 /*      const newPlgBalance = res[1].substring(0, res[1].length - 18)
      setPlgBalance(newPlgBalance)*/
      const newPlgAvailable = res.substring(0, res.length - 18)
      setPlgAvailable(newPlgAvailable) 

    })
  }

  const isUnsupportedChainIdError = error instanceof UnsupportedChainIdError || (library && library.currentProvider.networkVersion != 56)

  if(active) {
    library.eth.getBalance(account).then(res =>  setBalance((res/(10**18)).toFixed(2)))
  }


  const changePlg = value => {
    if(value > parseInt(plgAvailable)) {
      value = parseInt(plgAvailable)
    }
    setPlgAmount(value)
  }

  if(!metamaskInstalled) {
    return (
      <StyledSection  id="privateSale">
        <StyledContentWrapper>
          <>
            {/* <div className="subtitle">Connect your metamask wallet to BSC network!</div> */}
            <div className="content">
              <StyledMetamaskButton as="a" href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn" target="_blank" rel="nofollow">
                <img src={metamaskLogo} alt="metamask logo" />
                <div className="title">Metamask not found</div>
                <div className="text">Click to install metamask</div>
              </StyledMetamaskButton>
            </div>           
          </>
        </StyledContentWrapper>
      </StyledSection>
    )
  }

  return (
    <StyledSection  id="privateSale">
      <StyledContentWrapper>
          {isUnsupportedChainIdError && (
            <>
              {/* <div className="subtitle">Connect your metamask wallet to BSC network!</div> */}
              <div className="content">
                {detectMobileAndTablet(windowWidth) ? (
                  <StyledMetamaskButton as="a" href="https://medium.com/stakingbits/guide-to-arbitrum-and-setting-up-metamask-for-arbitrum-543e513cdd8b#:~:text=Connecting%20to%20Arbitrum%20on%20Metamask,Chain%20ID%3A%2042161" target="_blank">
                    <img src={bscLogo} alt="bsc logo" />
                    <div className="title">Wrong network</div>
                    <div className="text">See how to add BSC network to your metamask</div>
                  </StyledMetamaskButton>
                ):(
                  <StyledMetamaskButton onClick={switchNetwork}>
                    <img src={bscLogo} alt="bsc logo" />
                    <div className="title">Wrong network</div>
                    <div className="text">Click to change to BSC network</div>
                  </StyledMetamaskButton>
                )}
              </div>     
            </>
          )}
          {!isUnsupportedChainIdError && (
            <>
              {!active ? (
                <>
                  <div className="content">
                    <StyledMetamaskButton onClick={() => connect()}>
                      <img src={metamaskLogo} alt="metamask logo" />
                      <div className="title">Metamask</div>
                      <div className="text">Click to connect to your Metamask wallet</div>
                    </StyledMetamaskButton>
                  </div>
                </>
              )
              :
              (
                <>
                  <StyledIntro>
                    <div className="title">Advisors Vesting</div>
                    <div className="coins">
                      <span className="icon-wrapper">
                        <img src={plgIcon} />
                      </span>
                      <span className="icon-wrapper">
                        <img src={bscIcon} />
                      </span>
                    </div>
                  </StyledIntro>
                  <StyledForm
                    onSubmit={e => {
                      e.preventDefault();
                    }}
                  >
                    <div className="card__content">
                          {step === 1 && (
                            <div>
                              <StyledControl>
                                <div className="label inline">
                                  <span>Available to withdraw</span>
                                  {/* <span className="tag"><img src={plgLogo} /><span>PLG</span></span> */}
                                </div>
                                <div>{plgAvailable > 0 ? plgAvailable : 0} PLG</div>
                              </StyledControl>
                            </div>
                          )}
                        </div>
                    <div className="card__content">
                      <StyledActions>
                        <div>
                          <StyledButtonMin 
                            onClick={event => changePlg(plgAvailable)}
                          >
                            MAX
                          </StyledButtonMin>
                        </div>
                        <StyledControl>
                          <Input 
                            changed={event => changePlg(event.target.value)}
                            value={plgAmount}
                            type="number"
                            element="input"
                            placeholder="Ex. 500000"
                            min="0"
                          />
                        </StyledControl>
                        <button 
                          className={buttonLoading || !plgAmount || plgAmount <= 0 ? "disabled plunge-button" : "plunge-button"} 
                          onClick={Withdraw}
                        >
                          {buttonLoading ? 'Withrawing...' : 'Withdraw'}
                        </button>
                        
                      </StyledActions>
                      {/* <div className="small">Withdrawal starts right after TGE on 12th Dec - 14:00 UTC</div> */}
                    </div>
                  </StyledForm>
                </>
              )}
            </>
          )}
      </StyledContentWrapper>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </StyledSection>
  )
}


export default AdvisorsWithdraw